<template>
  <v-container fluid fill-height :class="$style.auth" class="d-flex justify-start align-center flex-column pa-0">
    <div v-html="htmlContent">
    </div>
    <div>
      <v-row class="d-flex justify-center">
        <v-col cols="6">
          <v-btn class="text-center" exact text block color="#5993ac" :class="$style.back" :to="{ name: 'account-switch' }">
            <v-icon :class="$style.back_icon">mdi-chevron-left</v-icon>戻る
            (Back)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import utility from "@/utility";

export default {
  data() {
    return {
      htmlContent:`
          <!DOCTYPE html>
          <html lang="ja">
          <head>
          <meta charset="utf-8">
          <title>RLT-008 アカウント切替確認画面</title>
          </head>
          <body>
            <h2>RLT-008 アカウント切替確認画面</h2>
            <form action="https://app.mlp-awv01.com/migration/confirm" method="GET">
              <input type="hidden" name="associateTid" value="SUonaVS3QcNDjr1y5sa4sijZ1M1wXQX3goEbF">
              <input type="hidden" name="associateFlag" value="1">
              <p><input type="submit" value="確認しました"></p>
            </form>
            <form action="https://app.mlp-awv01.com/migration/confirm" method="GET">
              <input type="hidden" name="associateTid" value="SUonaVS3QcNDjr1y5sa4sijZ1M1wXQX3goEbF">
              <input type="hidden" name="associateFlag" value="0">
              <p><input type="submit" value="中断する"></p>
            </form>
          </body>
          </html>
          `,
      resourceName: "accounts",
      formKey: "account-index",
      title: "アカウント情報",
      titleEn: "Account Details",
      input: {},
      fields: [
        {
          label: "ユーザ名",
          labelEn: "User Name",
          name: "name"
        },
        {
          label: "メールアドレス",
          labelEn: "Email Address",
          name: "email"
        },
        {
          label: "電話番号",
          labelEn: "Phone Number",
          name: "phone"
        },
        {
          label: "パスワード",
          labelEn: "Password",
          name: "password"
        }
      ]
    };
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    },
    userName() {
      const { accountInfo } = this.entity;
      if (accountInfo.firstName && accountInfo.lastName) {
        return `${accountInfo.firstName} ${accountInfo.lastName}` || "";
      }
      return "";
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
    this.$store.commit(`${this.resourceName}/entity/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();

    const data = this.$route.params.data;
    console.log('test-index: ', data); // Output the received data

    this.$store
      .dispatch(`${this.resourceName}/entity/getAccountInfo`)
      .then(() => {
        this.input = {
          name: this.userName,
          email: this.entity.accountInfo.mailAddress,
          phone: this.entity.accountInfo.phoneNumberInfo
            ? this.entity.accountInfo.phoneNumberInfo.phoneNumber
            : null
        };
      });
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.separator {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.back {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.back_icon {
  margin-top: 2px;
}

.account_remove {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.account_remove_icon {
  margin-top: 2px;
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}

.v-list {
  .v-list-item {
    .v-list-item__title {
      color: #718490;
      font-size: 1.3rem;
    }

    .v-list-item__subtitle {
      font-size: 1.2rem;
      color: #718490;
    }

    .v-list-item__icon {
      .v-icon {
        color: #718490;
      }
    }
  }
}
</style>
